export enum TasksEnum {
  DAILY = 'daily',
  ONE_TIME = 'one-time',
}

export enum PlatformListEnum {
  TELEGRAM = 'telegram',
  X = 'x',
  ZESH_PORTAL = 'zesh_portal',
}

export enum TaskTypeEnum {
  DAILY = 'daily',
  ONE_TIME = 'one-time',
}

export enum TaskIconsEnum {
  MEGAPHONE = 'Megaphone',
  PAPER_AIRPLANE = 'PaperAirplane',
  RAISED_X = 'RaisedX',
  RAISED_ZESH = 'RaisedZesh',
  POST_X = 'PostX',
  GROUP_ACTIVITY_TELEGRAM = 'GroupActivityTelegram',
  COMMENT_X = 'CommentX',
}

export interface ITask {
  title: string;
  key?: string;
  reward: number;
  rewardDetails?: string;
  platform: string;
  isCompleted: boolean;
  link: string;
  icon: TaskIconsEnum;
  type: TaskTypeEnum;
}
