import { Box, Badge as MUIBadge, Tab as MUITab, Tabs as MUITabs, TabProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { TasksEnum } from '../../types/tasks';
import { Calendar, Engage } from '../atoms/Icons';

function TasksNavigation({ currentTab = () => {} }: { currentTab: (value: TasksEnum) => void }) {
  const [value, setValue] = useState(0);

  // This should come from a global store, so it gets updated when the user completes a task
  const [availableDailyTasks, setAvailableDailyTasks] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        currentTab(TasksEnum.DAILY);
        break;
      case 1:
        currentTab(TasksEnum.ONE_TIME);
        break;
      default:
        currentTab(TasksEnum.DAILY);
    }
  };

  useEffect(() => {
    setAvailableDailyTasks(4);
  }, [setAvailableDailyTasks]);

  const Badge = styled(MUIBadge)(() => ({
    '.MuiBadge-badge': {
      top: '10px',
      right: '20px',
      backgroundColor: 'var(--button_color)',
      color: 'var(--text_color)',
    },
  }));

  const Tabs = styled(MUITabs)(() => ({
    '.MuiTabs-flexContainer': {
      backgroundColor: 'var(--bg_color)',
      borderRadius: '15px',
      maxWidth: '100%',
      padding: '3px',
      margin: '6px 10px 0 10px',
    },
    '.MuiButtonBase-root': {
      lineHeight: '23px',
      fontWeight: '600',
      color: 'var(--gray_button_bg)',
      minWidth: 'calc(100% / 5)',
      padding: '2px',
      textTransform: 'none',
      fontSize: '12px',
      minHeight: '36px',
      width: '50%',
      gap: '6px',
      filter: 'grayscale(1)',
    },
    '& .Mui-selected': {
      border: 'none',
      borderRadius: '10px',
      backgroundColor: 'var(--secondary_bg_color)',
      filter: 'none',
      color: 'var(--text_color) !important',
    },
    '.MuiTabs-indicator': {
      display: 'none',
    },
  }));

  const Tab = styled(MUITab)<TabProps>(() => {
    return {
      color: 'var(--text_color)',
    };
  });

  return (
    <Box position="relative">
      <Tabs
        sx={{
          maxWidth: '100%',
          minHeight: '50px',
        }}
        value={value}
        onChange={handleChange}
        centered
      >
        <Tab iconPosition="start" icon={<Calendar fontSize="14px" />} label="Daily Tasks" />
        <Tab iconPosition="start" icon={<Engage fontSize="14px" />} label="One Time Tasks" />
      </Tabs>
      <Badge badgeContent={availableDailyTasks} sx={{ position: 'absolute', top: 0, right: 0 }} />
    </Box>
  );
}

export default TasksNavigation;
