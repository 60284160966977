import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { findZeshyCountdownStartTimeVar, findZeshyCountdownVar } from '../../graphql/apollo';

const useFindZeshyCountdown = () => {
  const countdown = useReactiveVar(findZeshyCountdownVar);
  const startTime = useReactiveVar(findZeshyCountdownStartTimeVar);
  const [findZeshyCurrentCountdown, setFindZeshyCurrentCountdown] = useState(countdown);

  useEffect(() => {
    if (countdown > 0 && startTime !== null) {
      const interval = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const newCountdown = Math.max(countdown - elapsed, 0);

        // Skip the last second so we don't show the user 0m 0s for 1 second
        if (newCountdown <= 1000) {
          setFindZeshyCurrentCountdown(0);
          clearInterval(interval);
        } else {
          setFindZeshyCurrentCountdown(newCountdown);
        }
      }, 100);

      return () => clearInterval(interval);
    }
  }, [countdown, startTime]);

  return findZeshyCurrentCountdown;
};

export default useFindZeshyCountdown;
