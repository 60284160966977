import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import TabbingNavigation from '../components/organisms/TabbingNavigation';
import useGetMe from '../hooks/userInfo/useGetMe';
import { PagesEnum } from '../types/pages';
import AirdropPage from './airdrop';
import EngagePage from './engage';
import FindPage from './find';
import FriendsPage from './friends';
import HomePage from './home';

function TMAWrapper() {
  const [telegramLanguage, setTelegramLanguage] = useState('en');
  const [currentTab, setCurrentTab] = useState<PagesEnum>(PagesEnum.HOME);

  const TWA = (window as any)?.Telegram?.WebApp;
  const footerHeight = 'calc(100vh - 78px)';

  const { data, loading, error }: { data: { user: User }; loading: any; error: any } = useGetMe();

  const setColorsFromTelegramTheme = (telegramThemeParams: any) => {
    if (telegramThemeParams) {
      document.documentElement.style.setProperty('--accent_text_color', telegramThemeParams.accent_text_color);
      Object.keys(telegramThemeParams).forEach((key) => {
        document.documentElement.style.setProperty(`--${key}`, telegramThemeParams[key]);
      });
    }
  };

  // useEffect(() => {
  //   if (data && data.user) {
  //     // TODO: remove this mockedCountdown and uncomment the line below
  //     console.log('find_zeshy_timeout: ', data.user.find_zeshy_timeout);
  //     const mockedCountdown = 6000;
  //     // findZeshyCountdown(data.user.find_zeshy_timeout);
  //     // findZeshyCountdown(mockedCountdown);
  //   }
  // }, []);

  useEffect(() => {
    console.log(TWA);
    if (TWA) {
      TWA.ready();
      TWA.expand();

      const initData: string = TWA.initData;
      validateInitData(initData);

      setColorsFromTelegramTheme(TWA?.themeParams);
    }
  }, [TWA]);

  useEffect(() => {
    console.log(TWA);
    if (TWA) {
      TWA.ready();
      TWA.expand();

      const initData: string = TWA.initData;
      validateInitData(initData);

      setColorsFromTelegramTheme(TWA?.themeParams);
      setTelegramLanguage(TWA?.initDataUnsafe?.user?.language_code);
      console.log(telegramLanguage);
    } else {
      // this is a workaround to not need to do this in Telegram until production
      //   setColorsFromTelegramTheme({
      //     accent_text_color: '#f83b4c',
      //     bg_color: '#3e2222',
      //     button_color: '#f83b4c',
      //     button_text_color: '#ffffff',
      //     destructive_text_color: '#ef5b5b',
      //     header_bg_color: '#271616',
      //     hint_color: '#b1c3d5',
      //     link_color: '#f83b4c',
      //     secondary_bg_color: '#271616',
      //     section_bg_color: '#3e2222',
      //     section_header_text_color: '#b1c3d5',
      //     subtitle_text_color: '#b1c3d5',
      //     text_color: '#ffffff',
      //   });
      // console.log('data: ', data);
      // console.log('loading: ', loading);
      // console.log('error: ', error);
      // if (data && data.user) {
      //   const mockedCountdown = 15000;
      //   findZeshyCountdownVar(mockedCountdown);
      //   findZeshyCountdownStartTimeVar(Date.now());
      // }
    }
  }, [TWA, data, error, loading, telegramLanguage]);

  const validateInitData = async (initData: string) => {
    try {
      const response = await fetch('https://tma-poc-test.paul-1b0.workers.dev/validate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ initData }),
      });
      const result = await response.json();
      if (!result.valid) {
        // Handle invalid data, e.g., show an error message and prevent app load
        alert('Validation failed!');
        // You can also redirect the user or prevent rendering of the main app component
      }
    } catch (error) {
      console.error('Error validating initData:', error);
    }
  };

  const getCurrentTab = (value: PagesEnum) => {
    setCurrentTab(value);
  };

  return (
    <Box
      height="100%"
      width="100%"
      sx={{
        backgroundImage: 'url("/engage.png")',
        backgroundPositionX: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '250px auto',
        backgroundColor: 'var(--secondary_bg_color)',
        backgroundBlendMode: 'lighten',
      }}
    >
      {currentTab === PagesEnum.HOME ? (
        <Box sx={{ height: footerHeight }}>
          <HomePage />
          {/* {countdown} */}
        </Box>
      ) : null}
      {currentTab === PagesEnum.FRIENDS ? (
        <Box sx={{ height: footerHeight }}>
          <FriendsPage />
        </Box>
      ) : null}
      {currentTab === PagesEnum.FIND ? (
        <Box sx={{ height: footerHeight }}>
          <FindPage />
        </Box>
      ) : null}
      {currentTab === PagesEnum.ENGAGE ? (
        <Box sx={{ height: footerHeight }}>
          <EngagePage />
        </Box>
      ) : null}
      {currentTab === PagesEnum.AIRDROP ? (
        <Box sx={{ height: footerHeight }}>
          <AirdropPage />
        </Box>
      ) : null}
      <TabbingNavigation currentTab={getCurrentTab} />
    </Box>
  );
}

export default TMAWrapper;
