import { ApolloClient, DefaultOptions, InMemoryCache, from, makeVar } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

const httpLink = new BatchHttpLink({
  uri: `${process.env.REACT_APP_TMA_API_URL}/graphql`,
  batchMax: 5, // No more than 5 operations per batch
  batchInterval: 20, // Wait no more than 20ms after first batched operation
});

export const findZeshyCountdownVar = makeVar(0); // in milliseconds
export const findZeshyCountdownStartTimeVar = makeVar<number | null>(null); // in milliseconds, will be: Date.now() )

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        countdown: {
          read() {
            return findZeshyCountdownVar();
          },
        },
      },
    },
  },
});

export const createApolloClient = () => {
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  };

  return new ApolloClient({
    link: from([httpLink]),
    cache: cache,
    credentials: 'include',
    defaultOptions,
  });
};
