import { Box, Typography } from '@mui/material';
import { Coin, TresureChest } from './Icons';

interface IDailyChestProps {
  locked: boolean;
  size: 'small' | 'medium' | 'large';
  days: number;
  value: number;
}

function DailyChest({ locked, size, days, value }: IDailyChestProps) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="end" gap="7px">
      <Typography color="var(--text_color)" fontSize="12px" lineHeight="23px" fontWeight="500">
        {days} days
      </Typography>
      <Box position="relative">
        <Box sx={{ opacity: locked ? '0.4' : '1' }}>
          <TresureChest fontSize={size === 'small' ? '32px' : size === 'medium' ? '42px' : '56px'}></TresureChest>
        </Box>
        {locked ? (
          <Box position="absolute" left="50%" top="50%" sx={{ transform: 'translate(-50%, -50%)' }}>
            <Typography fontSize="18px" lineHeight="13px">
              🔒
            </Typography>
          </Box>
        ) : null}
      </Box>
      <Box display="flex" gap="1px" alignItems="center">
        <Coin fontSize={'10px'} />
        <Typography color="var(--accent_text_color)" fontSize="10px" lineHeight="13px" fontWeight="500">
          +{value}
        </Typography>
      </Box>
    </Box>
  );
}

export default DailyChest;
