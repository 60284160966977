import { Box, Button } from '@mui/material';
import { useState } from 'react';
import HomePageCard from '../../components/organisms/HomePageCard';

function HomePage() {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ height: '100%', backgroundImage: 'url("/gameBackground.png")', backgroundSize: 'cover' }}>
      {/* <UnityGame /> */}
      <Button onClick={handleOpen}>Open</Button>
      <Button onClick={handleClose}>Close</Button>
      <HomePageCard open={open} handleClose={handleClose} />
    </Box>
  );
}

export default HomePage;
