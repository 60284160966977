import { Box } from '@mui/material';
import { IFriend } from '../../types/friends';
import FriendCard from '../molecules/FriendCard';

function FriendsList({ friends }: { friends: IFriend[] }) {
  return (
    <Box margin="8px 0" overflow="scroll" width="calc(100% - 20px)" gap="9px" display="flex" flexDirection="column">
      {friends.map((friend, index) => (
        <FriendCard key={index} friend={friend} />
      ))}
    </Box>
  );
}

export default FriendsList;
