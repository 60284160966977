import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import FindZeshyDoor from '../atoms/FindZeshyDoor';

function FindZeshyDoors({ disabled }: { disabled: boolean }) {
  const [selectedDoor, setSelectedDoor] = useState<number | null>(null);

  const doorSelected = (doorNumber: number) => {
    if (disabled) return;
    // Should only allow 1 selection, disabled during development
    // TODO: re-enable for live
    // if (selectedDoor === null) {
    setSelectedDoor(doorNumber);
    // }
  };

  const doors = Array.from({ length: 9 }, (_, index) => index + 1);

  return (
    <Grid container direction="column">
      {Array.from({ length: 3 }).map((_, rowIndex) => (
        <Grid container item key={rowIndex} wrap="nowrap" justifyContent="space-evenly" columnGap="6px" rowGap="0">
          {doors.slice(rowIndex * 3, rowIndex * 3 + 3).map((doorNumber) => (
            <Grid item key={doorNumber}>
              <Box onClick={() => doorSelected(doorNumber)}>
                <FindZeshyDoor doorNumber={doorNumber} selectedDoorNumber={selectedDoor} />
              </Box>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
}

export default FindZeshyDoors;
