import { Box, Typography } from '@mui/material';
import { ITask } from '../../types/tasks';
import { Check, Coin } from '../atoms/Icons';
import TaskIcon from '../atoms/TaskIcon';

function TaskCard({ task }: { task: ITask }) {
  return (
    <Box
      display="flex"
      minHeight="90px"
      sx={{ backgroundColor: 'var(--bg_color)', borderRadius: '10px' }}
      alignItems="center"
      justifyContent="space-between"
      padding="0 18px"
    >
      <Box display="flex" alignItems="center" justifyContent="start" gap="18px" sx={{ filter: task.isCompleted ? 'grayscale(1)' : 'none' }}>
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '46%',
              left: '50%',
              width: '60px',
              height: '60px',
              backgroundColor: 'black',
              clipPath: 'circle(45%)',
              transform: 'translate(-50%, -50%)',
              zIndex: '1',
            }}
          ></div>
          <Box zIndex="2" position="relative">
            <TaskIcon taskIcon={task.icon} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography
            color={task.isCompleted ? 'var(--gray_button_bg)' : 'var(--text_color)'}
            fontSize="16px"
            lineHeight="17px"
            fontWeight="500"
          >
            {task.title}
          </Typography>
          <Box display="flex" alignItems="center" gap="2px">
            <Coin fontSize="12px" />
            <Typography fontSize="12px" lineHeight="23px" color={task.isCompleted ? 'var(--gray_button_bg)' : 'var(--accent_text_color)'}>
              +{task.reward}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ filter: task.isCompleted ? 'grayscale(1)' : 'none' }}>
        <Check fontSize="38px" />
      </Box>
    </Box>
  );
}

export default TaskCard;
