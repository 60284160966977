import { Box } from '@mui/material';
import { AirdropMilestoneIconsEnum, IMielstone } from '../../types/airdrop';
import AirdropMilestonesProgress from './AirdropMilestonesProgress';

function AirdropMilestones() {
  const milestones: IMielstone[] = [
    {
      title: 'Fundraising',
      target: '1.72M',
      progress: 35,
      icon: AirdropMilestoneIconsEnum.FUNDRAISING,
    },
    {
      title: 'Zeshians',
      target: '500K',
      progress: 18,
      icon: AirdropMilestoneIconsEnum.ZESHIANS,
    },
    {
      title: 'X Followers',
      target: '200k',
      progress: 5,
      icon: AirdropMilestoneIconsEnum.X_FOLLOWERS,
    },
  ];

  return (
    <Box width="100%" display="flex" flexDirection="column" gap="10px" marginBottom="20px">
      {milestones.map((milestone, index) => (
        <AirdropMilestonesProgress key={index} milestone={milestone} />
      ))}
    </Box>
  );
}

export default AirdropMilestones;
