import { gql } from '@apollo/client';

export const GetUserQuery = gql`
  query User($id: ID, $telegram_id: String) {
    user(id: $id, telegram_id: $telegram_id) {
      id
      first_name
      last_name
      telegram_id
      invite_hash
      find_zeshy_timeout
      email
      created_at
      updated_at
    }
  }
`;
