import { LinearProgress as MUILinearProgress, styled } from '@mui/material';
import { useEffect, useState } from 'react';

function AirdropMilestoneProgressBar({ progressValue }: { progressValue: number }) {
  const LinearProgress = styled(MUILinearProgress)(() => ({
    '.MuiLinearProgress-bar': {
      borderRadius: '22px',
      backgroundColor: 'var(--button_color)',
    },
  }));

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const duration = 1000;
    const steps = 50;
    const stepDuration = duration / steps;
    const stepValue = progressValue / steps;

    let currentProgress = 0;
    let currentStepDuration = stepDuration;

    const incrementProgress = () => {
      currentProgress += stepValue;
      currentStepDuration *= 0.9;

      if (currentProgress < progressValue) {
        setProgress(currentProgress);
        setTimeout(incrementProgress, currentStepDuration);
      } else {
        setProgress(progressValue);
      }
    };

    const timeoutId = setTimeout(incrementProgress, stepDuration);

    return () => clearTimeout(timeoutId);
  }, [progressValue]);

  return (
    <LinearProgress
      sx={{ height: '12px', borderRadius: '22px', backgroundColor: 'var(--bg_color)' }}
      variant="determinate"
      value={progress}
    />
  );
}

export default AirdropMilestoneProgressBar;
