import { Box, Button, IconButton, Typography } from '@mui/material';
import { Coin, Copy, Send } from '../../components/atoms/Icons';
import FriendsBonusCard from '../../components/molecules/FriendsBonusCard';
import FriendsEmptyState from '../../components/molecules/FriendsEmptyState';
import FriendsList from '../../components/organisms/FriendsList';
import { IFriend, IFriendsBonusCard } from '../../types/friends';

function FriendsPage() {
  const friendsBonusCards: IFriendsBonusCard[] = [
    {
      title: 'Friend with Regular account',
      reward: 500,
    },
    {
      title: 'Friend with Premium account',
      reward: 2000,
    },
  ];

  const friends: IFriend[] = [
    {
      name: 'Jane Smith',
      bonusForFriend: 50,
      bonusForUser: 200,
      avatar: 'https://randomuser.me/api/portraits/thumb/women/10.jpg',
    },
    {
      name: 'John Doe',
      bonusForFriend: 50,
      bonusForUser: 100,
      avatar: 'https://randomuser.me/api/portraits/thumb/men/75.jpg',
    },
    {
      name: 'Jane Doe',
      bonusForFriend: 50,
      bonusForUser: 100,
      avatar: 'https://randomuser.me/api/portraits/thumb/men/70.jpg',
    },
    {
      name: 'John Smith',
      bonusForFriend: 50,
      bonusForUser: 100,
      avatar: 'https://randomuser.me/api/portraits/thumb/men/80.jpg',
    },
  ];

  return (
    <Box display="flex" flexDirection="column" height="100%" maxWidth="100%" alignItems="center">
      {/* Header ~130px */}
      <Box maxWidth="100%" padding="15px 0 10px 25px" display="flex" flexDirection="column" gap="6px" alignItems="start">
        <Typography color="var(--text_color)" fontSize="18px" lineHeight="23px" fontWeight="500">
          Invite Friends!
        </Typography>
        <Typography color="var(--text_color)" width="60%" fontSize="10px" lineHeight="13px" fontWeight="500">
          Earn up to 20% bonus points from your friends. More friends, more bonuses!
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" width="100%" gap="6px">
        {friendsBonusCards.map((friendsBonusCard, index) => (
          <Box key={index} width="calc(100% - 20px)">
            <FriendsBonusCard friendsBonusCard={friendsBonusCard} />
          </Box>
        ))}
      </Box>
      {/* Content rest */}
      <Box display="flex" justifyContent="space-between" width="calc(100% - 20px)" marginTop="15px">
        <Typography color="var(--text_color)" fontSize="18px" lineHeight="23px" fontWeight="500" marginLeft="15px">
          My Friends
        </Typography>
        <Box display="flex" alignItems="center" gap="4px" justifyContent="center">
          <Coin fontSize="13px"></Coin>
          <Typography color="var(--accent_text_color)" fontSize="12px" lineHeight="23px" fontWeight="500">
            {String(friends.reduce((previousValue, currentValue) => previousValue + currentValue.bonusForUser, 0))}
          </Typography>
          <Typography color="var(--text_color)" fontSize="12px" lineHeight="23px" fontWeight="500">
            from Bonuses
          </Typography>
        </Box>
      </Box>
      {friends?.length ? (
        <FriendsList friends={friends}></FriendsList>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <FriendsEmptyState />
        </Box>
      )}
      <Box display="flex" width="calc(100% - 20px)" gap="15px" marginBottom="3px">
        <Button
          sx={{
            backgroundColor: 'var(--button_color) !important',
            width: '100%',
            borderRadius: '7px',
            fontSize: '13px',
            lineHeight: '15px',
            textTransform: 'none',
            fontWeight: '500',
          }}
          variant="contained"
          startIcon={<Send />}
        >
          Invite Friend
        </Button>
        <IconButton sx={{ backgroundColor: 'var(--button_color) !important', width: '50px', borderRadius: '7px' }}>
          <Copy fontSize="15px" />
        </IconButton>
      </Box>
    </Box>
  );
}

export default FriendsPage;
