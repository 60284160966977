import { ApolloProvider } from '@apollo/client';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import './App.css';
import { createApolloClient } from './graphql/apollo';
import TMAWrapper from './pages/wrapper';
import theme from './theme';

const client = createApolloClient();

function App() {
  return (
    <CssVarsProvider theme={theme}>
      <ApolloProvider client={client}>
        <TMAWrapper></TMAWrapper>
      </ApolloProvider>
    </CssVarsProvider>
  );
}

export default App;
