import { Box, Typography } from '@mui/material';
import { IMielstone } from '../../types/airdrop';
import AirdropMilestoneProgressBar from '../atoms/AirdropMilestoneProgressBar';
import AirdropMilestoneProgressIcon from '../atoms/AirdropMilestoneProgressIcon';

function AirdropMilestonesProgress({ milestone }: { milestone: IMielstone }) {
  return (
    <Box width="100%" display="flex" flexDirection="column" gap="5px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap="10px">
          <AirdropMilestoneProgressIcon milestoneIcon={milestone.icon} />
          <Box display="flex" flexDirection="column">
            <Typography fontSize="12px" lineHeight="21px" color="var(--gray_button_bg)" fontWeight={500}>
              {milestone.title}
            </Typography>
            <Typography fontSize="18px" lineHeight="21px" color="var(--text_color)" fontWeight={500}>
              {milestone.target}
            </Typography>
          </Box>
        </Box>
        <Typography fontSize="18px" lineHeight="21px" color="var(--text_color)" fontWeight={500}>
          {milestone.progress}%
        </Typography>
      </Box>
      <AirdropMilestoneProgressBar progressValue={milestone.progress} />
    </Box>
  );
}

export default AirdropMilestonesProgress;
