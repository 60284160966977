import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Button, IconButton, styled } from '@mui/material';

import { Snackbar as MUISnackbar } from '@mui/material';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import HomePageCardContent from '../molecules/HomePageCardContent';

function HomePageCard({ open, handleClose }: { open: boolean; handleClose: () => void }) {
  const Snackbar = styled(MUISnackbar)(() => ({
    '.MuiPaper-root': {
      backgroundColor: 'var(--bg_color)',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    '.MuiCardHeader-root': {
      padding: '18px 12.5px 0 12.5px',
    },
    '.MuiCardHeader-action': {
      margin: '0',
    },
    '.MuiCardHeader-action > .MuiButtonBase-root': {
      backgroundColor: 'var(--gray_button_bg)',
      padding: '5px',
    },
    '.MuiCardActions-root': {
      padding: '10px',
      display: 'flex',
    },
    '.MuiCardActions-root > .MuiButtonBase-root': {
      width: '100%',
      color: 'var(--button_text_color)',
      backgroundColor: 'var(--button_color)',
      borderRadius: '7px',
      padding: '12px',
      textTransform: 'none',
      fontSize: '13px',
      lineHeight: '15px',
    },
  }));

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      sx={{
        bottom: 'calc(78px + 5px) !important',
        left: '50% !important',
        transform: 'translate(-50%)',
        width: 'calc(100% - 20px)',
        minHeight: '380px',
        alignItems: 'end',
      }}
    >
      <Card>
        <CardHeader
          action={
            <IconButton onClick={handleClose} aria-label="settings">
              <CloseOutlinedIcon />
            </IconButton>
          }
        />

        <CardContent
          sx={{
            height: '100%',
            marginTop: '-26px',
            padding: '10px',
          }}
        >
          <HomePageCardContent />
        </CardContent>

        <CardActions disableSpacing>
          <Button onClick={handleClose} aria-label="Acknowledge">
            Yay!
          </Button>
        </CardActions>
      </Card>
    </Snackbar>
  );
}

export default HomePageCard;
