'use client';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

const theme = extendTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          '&.MuiButton-sizeMedium.MuiButton-outlined': {
            height: '36px',
          },
          '&.MuiButton-sizeMedium.MuiButton-contained': {
            height: '36px',
          },
          '&.MuiButton-sizeMedium.MuiButton-text': {
            height: '36px',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          border: 'none',
          borderBottom: 'none !important',
        },
        indicator: {
          border: 'none !important',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'white',
          textDecoration: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          width: '100%',
          borderRadius: 4,
          textAlign: 'center',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '.MuiInputBase-input': {
            '&:-webkit-autofill': {
              transitionDelay: '9999s',
              transitionProperty: 'background-color, color',
            },
          },
        },
      },
    },
  },
});

export default theme;
