import { useQuery } from '@apollo/client';
import { GetUserQuery } from '../../graphql/queries';

const useGetMe = () => {
  // This needs to be taken from the global state after the TMA and bot confirm who the user is
  const userQueryPayload: UserPayload = {
    telegram_id: '1234',
  };

  const { data, loading, error } = useQuery(GetUserQuery, {
    variables: userQueryPayload,
  });

  return {
    data,
    loading,
    error,
  };
};

export default useGetMe;
