import { Box, Typography } from '@mui/material';

enum DoorSelectionStateEnum {
  UNSELECTED,
  CORRECTLY_SELECTED,
  INCORRECTLY_SELECTED,
}

function FindZeshyDoor({
  state = DoorSelectionStateEnum.UNSELECTED,
  doorNumber,
  selectedDoorNumber,
}: {
  state?: DoorSelectionStateEnum;
  doorNumber: number;
  selectedDoorNumber: number | null;
}) {
  const doorElementSquareSize = 'min(calc((100vh - 200px - 70px - 40px) / 3), calc((100vw - 46px) / 3))';
  const correctDoorNumber = 6;

  const basePathForWrongDoorCharacter = 'wrongDoorCharacter';

  return (
    <Box position="relative">
      {doorNumber !== selectedDoorNumber ? (
        <img
          src="/door.png"
          loading="lazy"
          alt="Door"
          style={{
            border: '3px solid var(--bg_color)',
            borderRadius: '10px',
            overflow: 'hidden',
            height: doorElementSquareSize,
          }}
        />
      ) : selectedDoorNumber === correctDoorNumber ? (
        <>
          <Box
            position="absolute"
            top="0"
            left="50%"
            sx={{ backgroundColor: 'var(--button_color)', transform: 'translate(-50%, -40%)', zIndex: '30' }}
            padding="4px 10px"
            borderRadius="23px"
            width="max-content"
          >
            <Typography color="var(--text_color)" fontSize="10px" lineHeight="10px" fontWeight="700">
              Correct Door
            </Typography>
          </Box>
          <img
            src="/zeshyDoor.png"
            alt="Door"
            style={{
              zIndex: '20',
              position: 'absolute',
              height: '60%',
              left: '50%',
              bottom: '8px',
              transform: 'translate(-50%, 0)',
            }}
          />
          <img
            src="/glowingDoorFrame.png"
            alt="Door"
            style={{
              zIndex: 10,
              position: 'absolute',
              borderRadius: '10px',
              overflow: 'hidden',
              height: doorElementSquareSize,
            }}
          />
          <img
            src="/openedDoorFrame.png"
            alt="Door"
            style={{
              borderRadius: '10px',
              overflow: 'hidden',
              height: doorElementSquareSize,
              border: '3px solid var(--button_color)',
            }}
          />
        </>
      ) : (
        <>
          <Box
            position="absolute"
            top="0"
            left="50%"
            sx={{ backgroundColor: 'var(--destructive_text_color)', transform: 'translate(-50%, -40%)', zIndex: '30' }}
            padding="4px 10px"
            borderRadius="23px"
            width="max-content"
          >
            <Typography color="var(--text_color)" fontSize="10px" lineHeight="10px" fontWeight="700">
              Wrong Door
            </Typography>
          </Box>

          <img
            src={`/${basePathForWrongDoorCharacter}${Math.floor(Math.random() * 8) + 1}.png`}
            alt="Door"
            style={{
              zIndex: '20',
              position: 'absolute',
              height: '60%',
              left: '50%',
              bottom: '8px',
              transform: 'translate(-50%, 0)',
            }}
          />
          <img
            src="/glowingDoorFrame.png"
            alt="Door"
            style={{
              zIndex: 10,
              position: 'absolute',
              borderRadius: '10px',
              overflow: 'hidden',
              height: doorElementSquareSize,
            }}
          />
          <img
            src="/openedDoorFrame.png"
            alt="Door"
            style={{
              borderRadius: '10px',
              overflow: 'hidden',
              height: doorElementSquareSize,
              border: '3px solid var(--destructive_text_color)',
            }}
          />
        </>
      )}
    </Box>
  );
}

export default FindZeshyDoor;
