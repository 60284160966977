import { TaskIconsEnum } from '../../types/tasks';
import { CommentX, GroupActivityTelegram, Megaphone, PaperAirplane, PostX, RaisedX, RaisedZesh } from './Icons';

function TaskIcon({ taskIcon }: { taskIcon: TaskIconsEnum }) {
  const iconComponents = {
    [TaskIconsEnum.MEGAPHONE]: Megaphone,
    [TaskIconsEnum.PAPER_AIRPLANE]: PaperAirplane,
    [TaskIconsEnum.RAISED_X]: RaisedX,
    [TaskIconsEnum.RAISED_ZESH]: RaisedZesh,
    [TaskIconsEnum.POST_X]: PostX,
    [TaskIconsEnum.GROUP_ACTIVITY_TELEGRAM]: GroupActivityTelegram,
    [TaskIconsEnum.COMMENT_X]: CommentX,
  };

  if (!taskIcon) return <Megaphone fontSize="54px" />;

  const IconComponent = iconComponents[taskIcon];

  return <IconComponent fontSize="54px" />;
}

export default TaskIcon;
