import { Box, Typography } from '@mui/material';
import AirdropMilestones from '../../components/molecules/AirdropMilestones';

function AirdropPage() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="end"
      alignItems="center"
      gap="20px"
      sx={{
        backgroundImage: 'url("/airdropBackground.png")',
        height: '100%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: '-20px',
        backgroundBlendMode: 'lighten',
        backgroundColor: 'var(--secondary_bg_color)',
      }}
    >
      <Box width="75%">
        <Typography textAlign="center" color="var(--text_color)" fontSize="32px" lineHeight="38px" fontWeight="500">
          $ZESH AIRDROP is on the way!
        </Typography>
      </Box>
      <Box width="calc(100% - 40px)">
        <AirdropMilestones />
      </Box>
    </Box>
  );
}

export default AirdropPage;
