import { Box, Typography } from '@mui/material';
import { IFriendsBonusCard } from '../../types/friends';
import { Coin, TresureChest } from '../atoms/Icons';

function FriendsBonusCard({ friendsBonusCard }: { friendsBonusCard: IFriendsBonusCard }) {
  return (
    <Box
      display="flex"
      minHeight="70px"
      sx={{ backgroundColor: 'var(--bg_color)', borderRadius: '10px' }}
      alignItems="center"
      justifyContent="space-between"
      padding="0px 8px 0 12px"
    >
      <Box display="flex" alignItems="center" justifyContent="start" gap="18px">
        <Box
          marginTop="4px"
          sx={{
            position: 'relative',
            display: 'inline-block',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '46%',
              left: '50%',
              width: '60px',
              height: '60px',
              backgroundColor: 'black',
              clipPath: 'circle(45%)',
              transform: 'translate(-50%, -50%)',
              zIndex: '1',
            }}
          ></div>
          <Box zIndex="2" position="relative">
            <TresureChest fontSize="43px" />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography color="var(--text_color)" fontSize="16px" lineHeight="23px" fontWeight="500">
            {friendsBonusCard.title}
          </Typography>
          <Box display="flex" alignItems="center" gap="2px">
            <Coin fontSize="12px" />
            <Typography fontSize="12px" lineHeight="23px" color="var(--accent_text_color)">
              +{friendsBonusCard.reward}
            </Typography>
            <Typography fontSize="12px" lineHeight="23px" color="var(--text_color)">
              for you and your friend
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FriendsBonusCard;
