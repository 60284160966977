import { Box, Typography } from '@mui/material';
import { Clock } from '../atoms/Icons';

function ZeshyOnAMission({ timeLeft }: { timeLeft: number }) {
  const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);

  return (
    <Box
      position="absolute"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ zIndex: '50', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      width="60%"
      gap="5px"
    >
      <Clock fontSize="100px" />
      <Typography marginBottom="5px" textAlign="center" color="var(--text_color)" fontSize="18px" lineHeight="23px" fontWeight="500">
        Zeshy is on a mission. Get back in {minutes}m {seconds}s
      </Typography>
      <Typography textAlign="center" color="var(--text_color)" fontSize="12px" lineHeight="16px" fontWeight="500">
        While you wait, explore other activities on the app to earn more coins.
      </Typography>
    </Box>
  );
}

export default ZeshyOnAMission;
