export interface IMielstone {
  title: string;
  target: string;
  progress: number;
  icon: AirdropMilestoneIconsEnum;
}

export enum AirdropMilestoneIconsEnum {
  FUNDRAISING = 'Fundraising',
  ZESHIANS = 'Zeshians',
  X_FOLLOWERS = 'X_Followers',
}
