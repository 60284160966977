import { Box, Typography } from '@mui/material';

function FriendsEmptyState() {
  return (
    <Box
      display="flex"
      alignItems="start"
      justifyContent="center"
      sx={{
        width: 'calc(100% - 20px)',
        backgroundImage: 'url(/friendsEmpty.png)',
        height: '100%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: '30px',
        backgroundColor: 'var(--secondary_bg_color)',
        backgroundBlendMode: 'soft-light',
      }}
      width="calc(100% - 20px)"
    >
      <Typography
        color="var(--text_color)"
        fontSize="14px"
        lineHeight="23px"
        textAlign="center"
        justifyContent="start"
        paddingTop="35px"
        width="80%"
      >
        No friends joined yet, but don't give up! Keep inviting—your hard work will pay off!
      </Typography>
    </Box>
  );
}

export default FriendsEmptyState;
