import { Tab as MUITab, Tabs as MUITabs, TabProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { PagesEnum } from '../../types/pages';
import { Airdrop, Engage, Find, Friends, Home } from '../atoms/Icons';

function TabbingNavigation({ currentTab = () => {} }: { currentTab: (value: PagesEnum) => void }) {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        currentTab(PagesEnum.HOME);
        break;
      case 1:
        currentTab(PagesEnum.ENGAGE);
        break;
      case 2:
        currentTab(PagesEnum.FIND);
        break;
      case 3:
        currentTab(PagesEnum.FRIENDS);
        break;
      case 4:
        currentTab(PagesEnum.AIRDROP);
        break;
      default:
        currentTab(PagesEnum.HOME);
    }
  };

  const Tabs = styled(MUITabs)(() => ({
    '.MuiTabs-fixed': {
      display: 'flex',
      justifyContent: 'center',
      padding: '0 5px',
      maxWidth: '100vw',
    },
    '.MuiTabs-flexContainer': {
      backgroundColor: 'var(--bg_color)',
      borderRadius: '15px',
      width: '100%',
      padding: '5px',
    },
    '.MuiButtonBase-root': {
      lineHeight: '23px',
      fontWeight: '600',
      color: 'var(--text_color) !important',
      minWidth: 'calc(100% / 5)',
      padding: '5px 5px 0 5px',
      textTransform: 'none',
      fontSize: '12px',
      minHeight: '60px',
    },
    '& .Mui-selected': {
      border: 'none',
      borderRadius: '10px',
      backgroundColor: 'var(--secondary_bg_color)',
    },
    '.MuiTabs-indicator': {
      display: 'none',
    },
  }));

  const Tab = styled(MUITab)<TabProps>(() => {
    return {
      color: 'var(--text_color)',
    };
  });

  return (
    <Tabs
      sx={{
        zIndex: '100',
        position: 'fixed',
        transform: 'translate(-50%)',
        left: '50%',
        bottom: '4px',
        width: '100%',
        padding: 0,
      }}
      value={value}
      onChange={handleChange}
      centered
    >
      <Tab icon={<Home />} label="Home" />
      <Tab icon={<Engage />} label="Engage" />
      <Tab icon={<Find />} label="Zala" />
      <Tab icon={<Friends />} label="Friends" />
      <Tab icon={<Airdrop />} label="Airdrop" />
    </Tabs>
  );
}

export default TabbingNavigation;
