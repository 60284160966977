import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import DailyChest from '../atoms/DailyChest';
import { Coin } from '../atoms/Icons';

function HomePageCardContent() {
  const [invited, setInvited] = useState(true);

  useEffect(() => {
    setInvited(false);
  }, [setInvited]);

  return invited ? (
    <Box display="flex" height="100%" alignItems="center" justifyContent="space-between" flexDirection="column">
      <Typography color="var(--text_color)" fontSize="18px" lineHeight="23px" fontWeight="500">
        🎉 Congrats!
      </Typography>
      <Box height="65%" display="flex" flexDirection="column" alignItems="center" gap="16px">
        <Box display="flex" gap="10px" alignItems="center">
          <Coin />
          <Typography color="var(--accent_text_color)" fontSize="25px" lineHeight="23px" fontWeight="500">
            +500
          </Typography>
        </Box>
        <Typography width="50%" color="var(--text_color)" fontSize="14px" lineHeight="23px" textAlign="center">
          Thanks to your friend $FriendName, you start with 500 bonus coins! Enjoy!
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box display="flex" height="100%" alignItems="center" justifyContent="space-between" flexDirection="column">
      <Typography color="var(--text_color)" fontSize="18px" lineHeight="23px" fontWeight="500">
        Play Daily to Unlock
      </Typography>
      <Box width="100%" height="75%" display="flex" flexDirection="column" alignItems="center" gap="16px">
        <Box display="flex" gap="10px" alignItems="center">
          <Coin />
          <Typography color="var(--accent_text_color)" fontSize="25px" lineHeight="23px" fontWeight="500">
            +10,000
          </Typography>
        </Box>
        <Box width="80%" display="flex" justifyContent="space-evenly">
          <DailyChest locked={false} size="small" days={7} value={1000} />
          <DailyChest locked={true} size="medium" days={14} value={2500} />
          <DailyChest locked={true} size="large" days={28} value={6500} />
        </Box>
        <Typography color="var(--text_color)" fontSize="12px" lineHeight="23px">
          Next unlock in 2 days
        </Typography>
      </Box>
    </Box>
  );
}

export default HomePageCardContent;
