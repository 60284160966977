import { Box } from '@mui/material';
import { ITask } from '../../types/tasks';
import TaskCard from '../molecules/TaskCard';

function TaskList({ tasks }: { tasks: ITask[] }) {
  return (
    <Box display="flex" flexDirection="column" gap="5px" padding="0 10px" height="calc(100vh - 120px - 60px - 75px)" overflow="scroll">
      {tasks.map((task, index) => (
        <TaskCard key={index} task={task}></TaskCard>
      ))}
    </Box>
  );
}

export default TaskList;
