import { AirdropMilestoneIconsEnum } from '../../types/airdrop';
import { Find, Fundraising, RaisedX } from './Icons';

function AirdropMilestoneProgressIcon({ milestoneIcon }: { milestoneIcon: AirdropMilestoneIconsEnum }) {
  const iconComponents = {
    [AirdropMilestoneIconsEnum.FUNDRAISING]: Fundraising,
    [AirdropMilestoneIconsEnum.ZESHIANS]: Find,
    [AirdropMilestoneIconsEnum.X_FOLLOWERS]: RaisedX,
  };

  if (!AirdropMilestoneIconsEnum) return <Fundraising />;

  const IconComponent = iconComponents[milestoneIcon];

  return <IconComponent fontSize="54px" />;
}

export default AirdropMilestoneProgressIcon;
