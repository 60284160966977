import { Box, Typography } from '@mui/material';
import { Coin } from '../../components/atoms/Icons';
import FindZeshyDoors from '../../components/molecules/FindZeshyDoors';
import ZeshyOnAMission from '../../components/molecules/ZeshyOnAMission';
import useFindZeshyCountdown from '../../hooks/userInfo/getFindZeshyCountdown';

function FindPage() {
  const { rewardPerTimeSlot, timeSlot } = {
    rewardPerTimeSlot: 100,
    timeSlot: 20,
  };

  // This needs to be moved to a global state management, so the timer doesn't reset when the user navigates away from the tab
  // const initialTimeLeft = 3000;
  // const [timeLeft, setTimeLeft] = useState(initialTimeLeft);

  const countdown = useFindZeshyCountdown();

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTimeLeft((prevTimeLeft) => {
  //       const newTimeLeft = prevTimeLeft - 1000;
  //       return newTimeLeft > 0 ? newTimeLeft : 0;
  //     });
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      {/* Header */}
      <Box display="flex" height="200px" width="100%" justifyContent="start" alignItems="end">
        <Box marginLeft="-10px">
          <img width="190px" height="max-content" src="/zeshy.png" loading="lazy" alt="Zeshy" />
        </Box>
        <Box display="flex" flexDirection="column" gap="15px" marginBottom="30px">
          <Typography color="var(--text_color)" fontSize="32px" lineHeight="23px" fontWeight="500">
            Find Zeshy
          </Typography>
          <Box display="flex" flexDirection="column" gap="0">
            <Box display="flex" alignItems="center" gap="2px">
              <Typography color="var(--text_color)" fontSize="10px" lineHeight="13px">
                +{rewardPerTimeSlot}
              </Typography>
              <Coin fontSize="11px"></Coin>
              <Typography color="var(--text_color)" fontSize="10px" lineHeight="13px">
                every {timeSlot} minutes
              </Typography>
            </Box>
            <Typography color="var(--text_color)" fontSize="10px" lineHeight="13px">
              Select a door to find Zeshy
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box height="100%" width="99vw" display="flex" alignItems="end" justifyContent="center" position="relative">
        {countdown ? <ZeshyOnAMission timeLeft={countdown} /> : null}
        <Box
          sx={{
            border: countdown > 0 ? '2px solid var(--text_color)' : '2px solid transparent',
            borderRadius: '10px',
          }}
        >
          <Box
            sx={{
              filter: countdown > 0 ? 'brightness(0.3)' : 'none',
              pointerEvents: countdown > 0 ? 'none' : 'auto',
            }}
          >
            <FindZeshyDoors disabled={countdown > 0} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FindPage;
