import { CheckCircle } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import { IFriend } from '../../types/friends';
import { Coin } from '../atoms/Icons';

function FriendCard({ friend }: { friend: IFriend }) {
  return (
    <Box
      display="flex"
      minHeight="70px"
      sx={{ backgroundColor: 'var(--bg_color)', borderRadius: '10px' }}
      alignItems="center"
      justifyContent="space-between"
      padding="0px 8px 0 12px"
    >
      <Box display="flex" alignItems="center" justifyContent="start" gap="18px">
        <Box
          marginTop="4px"
          sx={{
            position: 'relative',
            display: 'inline-block',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '46%',
              left: '50%',
              width: '60px',
              height: '60px',
              backgroundColor: 'var(--button_color)',
              clipPath: 'circle(47%)',
              transform: 'translate(-50%, -50%)',
              zIndex: '1',
            }}
          ></div>
          <Box zIndex="2" position="relative">
            <img
              width="45px"
              height="max-content"
              src={friend.avatar}
              loading="lazy"
              alt="Zeshy"
              style={{ borderRadius: '100%', border: '4px solid var(--bg_color)' }}
            />
          </Box>
          <CheckCircle
            sx={{
              color: 'var(--button_color)',
              position: 'absolute',
              backgroundColor: 'var(--text_color)',
              borderRadius: '100%',
              fontSize: '18px',
              bottom: '2px',
              right: '-3px',
              zIndex: 10,
            }}
          ></CheckCircle>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography color="var(--text_color)" fontSize="14px" lineHeight="18px" fontWeight="500">
            {friend.name}
          </Typography>
          <Box display="flex" alignItems="center" gap="2px">
            <Coin fontSize="12px" />
            <Typography fontSize="12px" lineHeight="23px" fontWeight="500" color="var(--accent_text_color)">
              +{friend.bonusForFriend}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" gap="16px">
        <Divider sx={{ borderColor: 'var(--secondary_bg_color)' }} orientation="vertical" flexItem></Divider>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography color="var(--text_color)" fontSize="12px" lineHeight="16px" fontWeight="500">
            Bonus for you
          </Typography>
          <Box display="flex" alignItems="center">
            <Coin fontSize="12px" />
            <Typography fontSize="12px" lineHeight="16px" fontWeight="500" color="var(--accent_text_color)">
              +{friend.bonusForUser}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FriendCard;
