import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { TresureChest } from '../../components/atoms/Icons';
import TaskList from '../../components/organisms/TaskList';
import TasksNavigation from '../../components/organisms/TasksNavigation';
import { ITask, PlatformListEnum, TaskIconsEnum, TaskTypeEnum, TasksEnum } from '../../types/tasks';

function EngagePage() {
  const [currentTab, setCurrentTab] = useState<TasksEnum>(TasksEnum.DAILY);

  const getCurrentTab = (value: TasksEnum) => {
    setCurrentTab(value);
  };

  const tasks: ITask[] = [
    {
      title: 'Like pinned on X',
      key: 'like-pinned-x',
      reward: 50,
      platform: PlatformListEnum.X,
      isCompleted: false,
      link: 'https://x.com',
      icon: TaskIconsEnum.COMMENT_X,
      type: TaskTypeEnum.DAILY,
    },
    {
      title: 'Repost pinned on X',
      reward: 100,
      platform: PlatformListEnum.X,
      isCompleted: true,
      link: 'https://x.com',
      icon: TaskIconsEnum.COMMENT_X,
      type: TaskTypeEnum.DAILY,
    },
    {
      title: 'Comment pinned on X',
      reward: 150,
      platform: PlatformListEnum.X,
      isCompleted: false,
      link: 'https://x.com',
      icon: TaskIconsEnum.COMMENT_X,
      type: TaskTypeEnum.DAILY,
    },
    {
      title: 'Telegram Group Activity',
      reward: 200,
      rewardDetails: '(50 coins/quality message)',
      platform: PlatformListEnum.TELEGRAM,
      isCompleted: false,
      link: 'https://telegram.com',
      icon: TaskIconsEnum.GROUP_ACTIVITY_TELEGRAM,
      type: TaskTypeEnum.DAILY,
    },
    {
      title: 'React to latest post in our channel',
      reward: 100,
      platform: PlatformListEnum.TELEGRAM,
      isCompleted: false,
      link: 'https://telegram.com',
      icon: TaskIconsEnum.COMMENT_X,
      type: TaskTypeEnum.DAILY,
    },
    {
      title: 'Join our Channel',
      reward: 1000,
      platform: PlatformListEnum.TELEGRAM,
      isCompleted: false,
      link: 'https://telegram.com',
      icon: TaskIconsEnum.MEGAPHONE,
      type: TaskTypeEnum.ONE_TIME,
    },
    {
      title: 'Join our Group',
      reward: 500,
      platform: PlatformListEnum.TELEGRAM,
      isCompleted: true,
      link: 'https://telegram.com',
      icon: TaskIconsEnum.PAPER_AIRPLANE,
      type: TaskTypeEnum.ONE_TIME,
    },
    {
      title: 'Follow Zesh on X',
      reward: 2000,
      platform: PlatformListEnum.X,
      isCompleted: false,
      link: 'https://x.com',
      icon: TaskIconsEnum.RAISED_X,
      type: TaskTypeEnum.ONE_TIME,
    },
    {
      title: 'Open Zesh Account',
      reward: 5000,
      platform: PlatformListEnum.ZESH_PORTAL,
      isCompleted: false,
      link: 'https://zesh.com',
      icon: TaskIconsEnum.RAISED_ZESH,
      type: TaskTypeEnum.ONE_TIME,
    },
    {
      title: 'Post on X',
      reward: 2000,
      platform: PlatformListEnum.X,
      isCompleted: false,
      link: 'https://x.com',
      icon: TaskIconsEnum.POST_X,
      type: TaskTypeEnum.ONE_TIME,
    },
  ];

  return (
    <Box display="flex" flexDirection="column" height="100%" maxWidth="100%">
      {/* Header ~130px */}
      <Box maxWidth="100%" padding="43px 20px 20px 20px" display="flex" gap="15px" alignItems="center">
        <TresureChest fontSize="52px" />
        <Typography color="var(--text_color)" width="40%" fontSize="18px" lineHeight="23px" fontWeight="500">
          Engage to Earn More Coins
        </Typography>
      </Box>
      {/* Tabbing 40px */}
      <TasksNavigation currentTab={getCurrentTab}></TasksNavigation>
      {/* Content rest */}
      <Box margin="8px 0">
        {currentTab === TasksEnum.DAILY && <TaskList tasks={tasks.filter((task) => task.type === TaskTypeEnum.DAILY)}></TaskList>}
        {currentTab === TasksEnum.ONE_TIME && <TaskList tasks={tasks.filter((task) => task.type === TaskTypeEnum.ONE_TIME)}></TaskList>}
      </Box>
    </Box>
  );
}

export default EngagePage;
